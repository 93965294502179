import { render, staticRenderFns } from "./setUp.vue?vue&type=template&id=b9f98d30&scoped=true&"
import script from "./setUp.vue?vue&type=script&lang=js&"
export * from "./setUp.vue?vue&type=script&lang=js&"
import style0 from "./setUp.vue?vue&type=style&index=0&id=b9f98d30&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b9f98d30",
  null
  
)

export default component.exports