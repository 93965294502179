<template>
    <div class="table-box">
        <el-table
            ref="multipleTable"
            :data="tableData"
            height="100%"
            :header-cell-style="{
                background: '#FAFAFA',
                color: '#333333',
                'font-size': '14px',
                'font-weight': '500',
            }"
        >
            <el-table-column label="日期" show-overflow-tooltip width="100">
                <template slot-scope="scope">
                    <div>
                        {{
                            scope.row.accountTime
                                ? scope.row.accountTime.slice(0, 10)
                                : '- -'
                        }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="部门" width="80" show-overflow-tooltip>
                <template slot-scope="scope">
                    <!-- <el-tooltip placement="top"
                        >·
                        <div slot="content">
                            <p
                                style="line-height: 2"
                                v-for="item in scope.row.departmentAdminDOList"
                                :key="item.id"
                            >
                                {{ item.departmentName }}
                            </p>
                        </div>
                        <em style="font-style: normal">
                            {{ returnName(scope.row) }}
                        </em>
                    </el-tooltip> -->
                    <div>
                        {{ scope.row.departmentName || '- -' }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="成员"
                prop="companyDomain"
                width="55"
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <div>
                        {{ scope.row.adminName || '- -' }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="客户" show-overflow-tooltip width="239">
                <template slot-scope="scope">
                    <span>{{
                        scope.row.companyName ? scope.row.companyName : '- -'
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="产品" width="120" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span> {{ returnProductName(scope.row) }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="所属关系"
                width="80"
                prop="name"
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <div>
                        {{
                            scope.row.adminIdType == 1
                                ? '所属人'
                                : scope.row.adminIdType == 2
                                ? '协作人'
                                : scope.row.adminIdType == 3
                                ? '合作人'
                                : '- -'
                        }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="标的" width="170" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span> {{ returnContractTarget(scope.row) }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>

            <el-table-column
                label="销售费用"
                width="110"
                prop="name"
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <div>
                        {{
                            scope.row.businessDeclareId
                                ? returnDeclare(scope.row)
                                : '未申请' || '- -'
                        }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="结算价"
                width="80"
                prop="name"
                show-overflow-tooltip
            >
                <template slot-scope="scope">

                    <div v-if="btnP.update">
                        <el-button
                            style="color: #2370eb; text-decoration: underline"
                            type="text"
                            @click="onSetUp(scope.row)"
                            > {{ scope.row.settleAmount || '- -' }}</el-button
                        >
                    </div>
                    <div v-else>
                        {{ scope.row.settleAmount || '- -' }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="应收金额"
                width="80"
                prop="name"
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <div>
                        {{ scope.row.receivableAmount || 0 }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="实收金额"
                width="80"
                prop="name"
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <div>
                        {{ scope.row.actualAmount || 0 }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="累计实收金额"
                width="90"
                prop="name"
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <div>
                        {{ scope.row.collectionAmount || 0 }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="回款进度"
                width="80"
                prop="name"
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <div>
                        {{
                            parseInt(
                                (scope.row.collectionAmount /
                                    scope.row.receivableAmount) *
                                    100
                            ) + '%'
                        }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="业绩"
                width="80"
                prop="name"
                show-overflow-tooltip
                fixed="right"
            >
                <template slot-scope="scope">
                    <div>
                        <el-button
                            style="color: #2370eb; text-decoration: underline"
                            type="text"
                            @click="onDetails2(scope.row)"
                            >{{ scope.row.performanceAmount || 0 }}</el-button
                        >
                    </div>
                </template>
            </el-table-column>

            <el-table-column label="状态" fixed="right">
                <template slot-scope="scope">
                    <el-button
                        :style="{
                            color:
                                scope.row.status == 1
                                    ? '#D0021B'
                                    : scope.row.status == 2
                                    ? '#2370eb'
                                    : '#F5A623',
                            textDecoration:
                                scope.row.status == 2 ? 'underline' : '',
                        }"
                        type="text"
                        @click="onDetails(scope.row)"
                        >{{
                            $cost.performanceStatus(scope.row.status)
                        }}</el-button
                    >
                </template>
            </el-table-column>
            <div slot="empty" class="empty">
                <img src="@/assets/zanwushuju.png" />
                <span style="display: block">暂无数据～</span>
            </div>
        </el-table>


        <!-- s设置状态 -->
        <el-dialog
            :visible.sync="dialogSetUp"
            width="560px"
            :before-close="handleClose"
            append-to-body
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    修改业绩
                </div>
            </template>
            <SetUp ref="setUp" @close="handleClose" />
        </el-dialog>
    </div>
</template>

<script>
import { performanceList } from '@/api/performance/performance.js';
import SetUp from './setUp.vue';
export default {
    name: '',
    props: {},
    data() {
        return {
            tableData: [],
            tableRow: {},
            dialog: false,
            dialogSetUp: false,
            btnP: {},
            pageData: {},
        };
    },
    components: {SetUp},
    created() {},
    methods: {
        getData(active, searchVal, pageNum, pageSize, btnP) {
            this.btnP = btnP;
            let data = {
                param: {},
                pageSize: pageSize,
                pageNum: pageNum,
            };
            if (btnP.userSearch && !btnP.depSearch) {
                data.param.departmentId =
                    sessionStorage.getItem('departmentId');

                if (searchVal.adminId) {
                    data.param.adminId = searchVal.adminId;
                }
            }

            if (btnP.depSearch) {
                if (searchVal.adminId) {
                    data.param.adminId = searchVal.adminId;
                }
                if (searchVal.departmentId) {
                    data.param.departmentId = searchVal.departmentId;
                }
            }
            if (!btnP.userSearch && !btnP.depSearch) {
                data.param.departmentId =
                    sessionStorage.getItem('departmentId');
                data.param.adminId = sessionStorage.getItem('adminId');
            }
            if (active == 0) {
                data.param.statusList = [1, 2, 3, 5];
            } else {
                data.param.status = active;
            }
            if (searchVal.productCategory) {
                data.param.productCategory = searchVal.productCategory;
            }
            if (searchVal.accountMonth) {
                data.param.accountMonth = searchVal.accountMonth;
            }
            this.pageData = {
                active: active,
                searchVal: searchVal,
                pageNum: pageNum,
                pageSize: pageSize,
            };
            performanceList(data).then((res) => {
                if (res.code == 200) {
                    this.$emit('totalNum', res.data.total);
                    this.tableData = res.data.list;
                }
            });
        },
        onDetails(row) {
            var now = new Date();
            var year = now.getFullYear(); //得到年份
            var month = now.getMonth(); //得到月份
            var accountTimeStr = row.accountTime;
            accountTimeStr = accountTimeStr.replace(/-/g,"/");
            var accountDate = new Date(accountTimeStr);
            var accountYear = accountDate.getFullYear();
            var accountMonth = accountDate.getMonth();
            if(accountYear == year){
                if(month <= accountMonth){
                    return this.$message.error("只能确认当月之前的业绩");
                }
            }

            
            if (row.status == 2) {
                sessionStorage.setItem(
                    'pageData',
                    JSON.stringify(this.pageData)
                );
                this.$router.push({
                    path: '/myachievementdetails',
                    query: {
                        id: row.id,
                    },
                });
            }
        },
        onDetails2(row) {
            var now = new Date();
            var year = now.getFullYear(); //得到年份
            var month = now.getMonth(); //得到月份
            var accountTimeStr = row.accountTime;
            accountTimeStr = accountTimeStr.replace(/-/g,"/");
            var accountDate = new Date(accountTimeStr);
            var accountYear = accountDate.getFullYear();
            var accountMonth = accountDate.getMonth();
            if(accountYear == year){
                if(month <= accountMonth){
                    return this.$message.error("只能查看当月之前的业绩");
                }
            }
            // if (row.status == 2) {
            sessionStorage.setItem('pageData', JSON.stringify(this.pageData));
            this.$router.push({
                path: '/myachievementdetails',
                query: {
                    id: row.id,
                },
            });
            // }
        },

        returnProductName(data) {
            let text = '';
            if (data.contractTargetBO) {
                data.contractTargetBO.productAttributeRelationBOList.forEach(
                    (item) => {
                        text = item.productName;
                    }
                );
            }
            return text;
        },
        returnContractTarget(data) {
            console.log(data);
            let text = '';
            if (data.contractTargetBO) {
                if (this.returnProductName(data) == '域名') {
                    let domainType =
                        data.contractTargetBO.areaType == 1
                            ? '中国域名'
                            : data.contractTargetBO.areaType == 2
                            ? '国际域名'
                            : '其他';
                    text = data.contractTargetBO.term + '年,' + domainType;
                } else if (this.returnProductName(data).indexOf('邮箱') > 0) {
                    if (data.contractTargetBO.productType == 3) {
                        text = data.contractTargetBO.number + '用户';
                    } else {
                        text =
                            data.contractTargetBO.number +
                            '用户,' +
                            data.contractTargetBO.term +
                            '年';
                    }
                } else {
                    text = data.contractTargetBO.number + '用户';
                }

                if (data.contractTargetBO.productCategory == 1) {
                    if (data.contractTargetBO.productType == 1) {
                        text = text + ',购买';
                    } else if (data.contractTargetBO.productType == 2) {
                        text = text + ',续费';
                    } else if (data.contractTargetBO.productType == 3) {
                        text = text + ',增购';
                    }
                    if (data.contractTargetBO.version == 1) {
                        text = text + ',老版';
                    } else {
                        text = text + ',新版';
                    }
                } else if (data.contractTargetBO.productCategory == 2) {
                    if (data.contractTargetBO.productType == 1) {
                        text = text + ',购买';
                    } else if (data.contractTargetBO.productType == 2) {
                        text = text + ',续费';
                    } else if (data.contractTargetBO.productType == 3) {
                        text = text + ',增购';
                    }
                    if (data.contractTargetBO.version == 1) {
                        text = text + ',标准版';
                    } else if (data.contractTargetBO.version == 2) {
                        text = text + ',尊享版';
                    } else {
                        text = text + ',集团版';
                    }
                } else if (data.contractTargetBO.productCategory == 4) {
                    if (data.contractTargetBO.productType == 1) {
                        text = text + ',购买';
                    } else if (data.contractTargetBO.productType == 2) {
                        text = text + ',续费';
                    } else if (data.contractTargetBO.productType == 3) {
                        text = text + ',增购';
                    }

                    if (data.contractTargetBO.version == 1) {
                        text = text + ',Hmail旗舰版';
                    } else if (data.contractTargetBO.version == 2) {
                        text = text + ',Hmail5G';
                    } else {
                        text = text + ',尊享版';
                    }
                }
            }
            return text;
        },
        returnText(type, orderType = '') {
            let text = '';
            if (this.singleData.id) {
                this.singleData.productAttributeRelationBOList.forEach(
                    (item) => {
                        if (item.attributeColumnName == type) {
                            item.productAttributeValueBOList.forEach((itm) => {
                                if (orderType) {
                                    if (
                                        itm.attributeValue ==
                                        this.singleData[orderType]
                                    ) {
                                        text = itm.attributeValueName;
                                    }
                                } else {
                                    if (
                                        itm.attributeValue ==
                                        this.singleData[type]
                                    ) {
                                        text = itm.attributeValueName;
                                    }
                                }
                            });
                        }
                    }
                );
            }
            return text;
        },

        onSetUp(row) {
            this.dialogSetUp = true;
            setTimeout(() => {
                this.$refs.setUp.getData(row);
            });
        },
        handleClose() {
            this.dialogSetUp = false;
            this.$emit('searchEmpty');
        },

        returnDeclare(data) {
            let text = '';
            if (data.businessDeclareBO) {
                let declareType =
                    data.businessDeclareBO.declareType == 1 ? '订单' : '月度';
                text =
                    declareType + ':' + data.businessDeclareBO.amount + '(￥)';
            }

            return text;
        },
        returnName(data) {
            var str = '';
            data.departmentAdminDOList
                ? data.departmentAdminDOList.forEach((item) => {
                      if (item.main == 1) {
                          str = item.departmentName;
                      }
                  })
                : '';
            return str;
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.table-box {
    width: 100%;
    height: 100%;
    .icon-ziyuanxhdpi {
        color: #2370eb;
        cursor: pointer;
        vertical-align: -1px;
    }
}

/deep/ .el-table .cell {
    text-overflow: initial;
    padding-right: 0;
}
.dl_con {
    width: 80%;
    margin: 35px auto 100px;
    text-align: center;
    color: #333;
    font-weight: 600;
    font-size: 14px;
    i {
        font-weight: 400;
        font-size: 60px;
        color: #f5a623;
        display: block;
        margin-bottom: 20px;
    }
}
/deep/.el-dialog__footer {
    padding: 0;
    text-align: right;
    height: 50px;
    line-height: 50px;
    border-top: 1px solid #eeeeee;
    padding-right: 18px;

    .el-button--primary {
        background-color: #2370eb;
    }
}
</style>
