<template>
    <div class="deal_with_box">
        <div class="search-box">
            <div class="search-one">
                <p class="left">
                    <Department
                        ref="department"
                        :departmentWidth="180"
                        :adminWidth="180"
                        :depSearch="btnP.depSearch"
                        :searchUser="btnP.userSearch"
                        @searchData="searchData"
                        :isAchievement="true"
                    ></Department>
                    <span>月份</span>
                    <el-date-picker
                        style="width: 160px"
                        v-model="searchVal.accountMonth"
                        value-format="yyyy-MM"
                        type="month"
                        size="small"
                        placeholder="选择年月"
                        @change="(currentPage = 1), (pagesize = 20), getData()"
                    >
                    </el-date-picker>
                    <!-- :picker-options="expireTimeOption" -->
                    <clue-product
                        ref="clueproduct"
                        @changeProductId="changeProductId"
                        :productWidth="160"
                        :isAchievement="true"
                    ></clue-product>
                   
                </p>
                <p>
                        <el-button
                            size="mini"
                            type="primary"
                            icon="el-icon-arrow-left"
                            style="
                                background: #fff;
                                color: #2370eb;
                                border-color: #2370eb;
                                margin-right: 12px;
                            "
                            @click="onClose"
                            >返回列表</el-button
                        > 
                    </p>
            </div>
        </div>
        <div class="table-title">
            <div>
                <div class="title_left_btn">
                    <span
                        :class="active == 0 ? 'active' : ''"
                        @click="changeActive(0)"
                        >全部</span
                    >

                    <span
                        :class="active == 2 ? 'active' : ''"
                        @click="changeActive(2)"
                        >待确认</span
                    >
                    <span
                        :class="active == 3 ? 'active' : ''"
                        @click="changeActive(3)"
                        >已确认</span
                    >
                    <!-- <el-badge :value="rejectNum" class="item">
                        <span :class="active == 4 ? 'active' : ''">已驳回</span>
                    </el-badge> -->
                    <span
                        :class="active == 1 ? 'active' : ''"
                        @click="changeActive(1)"
                        >未到款</span
                    >
                </div>
            </div>
            <p>
                    <button class="cancel" v-if="btnP.email" @click="onEmailDetail()">
                        邮件发送业绩
                    </button>
                    <button class="cancel" v-if="btnP.emailFiance" @click="onEmailFiance()">
                        邮件发送财务
                    </button>
                </p>
        </div>
        <Table ref="table" @searchEmpty="getData" @totalNum="totalNum"></Table>
        <div class="page-box">
            <el-pagination
                v-if="isPage"
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                :current-page.sync="currentPage"
                :page-size="pagesize"
                :page-sizes="[10, 20, 50]"
                layout="total,sizes,prev, pager, next, jumper"
                :total="total"
            ></el-pagination>
        </div>
    </div>
</template>

<script>
import { departmentList } from '@/api/admin/framework/framework.js';
import Table from '../table/table.vue';
import Department from '../../../../components/Department';
import clueProduct from '../../../../components/clueProduct.vue';
import { performanceDetail,performanceEmailFiance } from '@/api/performance/performance.js';
export default {
    data() {
        return {
            active: 0,
            currentPage: 1,
            pagesize: 20,
            total: 0,
            searchVal: {
                adminId: '',
                departmentId: '',
                productCategory: '',
                accountMonth: this.getDate(),
            },
            direction: 'rtl',
            dialogGenerate: false,
            dialogSend: false,
            tableRow: {},
            departmentList: [],
            userName: [],
            allUserList: [],
            btnP: {},
            form: {
                radio: 1,
            },
            rejectNum: 0,
            isPage: false,
            tag1: false,
            tag2: false,
            nowDateDay: new Date().getDate(),
            // expireTimeOption: {
            //     disabledDate(date) {
            //         // disabledDate 文档上：设置禁用状态，参数为当前日期，要求返回 Boolean
            //         return date.getTime() < Date.now();
            //     },
            // },
        };
    },
    components: {
        Table,
        Department,
        clueProduct,
    },
    created() {},
    mounted() {
        this.getBtn();
    },
    methods: {
        getDate() {
            var now = new Date();
            var year = now.getFullYear(); //得到年份
            var month = now.getMonth(); //得到月份
            month = month + 1;
            month = month.toString().padStart(2, '0');
            var defaultDate = `${year}-${month}`; //
            return defaultDate;
        },
        getBtn() {
            var btnArr = JSON.parse(sessionStorage.getItem('isButArr'));
            let btn = {};
            for (let i in btnArr) {
                btn['' + btnArr[i].route] = true;
                if (btnArr[i].children && btnArr[i].children.length > 0) {
                    btnArr[i].children.forEach((item) => {
                        btn['' + item.route] = true;
                        if (item.children && item.children.length > 0) {
                            item.children.forEach((itm) => {
                                btn['' + itm.route] = true;
                            });
                        }
                    });
                }
            }
            this.btnP = btn;
            this.$refs.clueproduct.getData();

            if (btn.depSearch) {
                this.getDepartmentList();
            }
            if (btn.userSearch && !btn.depSearch) {
                this.$refs.department.$data.adminId = Number(
                    sessionStorage.getItem('adminId')
                );
                this.searchVal.adminId = Number(
                    sessionStorage.getItem('adminId')
                );
                this.$refs.department.getUserList(
                    sessionStorage.getItem('departmentId')
                );
            }

            if (btn.fullpay || btn.allorder) {
                this.getAllUser();
            }
            this.getPageData();
        },
        getPageData() {
            if (
                sessionStorage.getItem('pageData') &&
                JSON.parse(sessionStorage.getItem('pageData'))
            ) {
                this.currentPage = JSON.parse(
                    sessionStorage.getItem('pageData')
                ).pageNum;
                this.pagesize = JSON.parse(
                    sessionStorage.getItem('pageData')
                ).pageSize;
                this.searchVal =
                    JSON.parse(sessionStorage.getItem('pageData')).searchVal ||
                    {};
                this.$refs.department.$data.departmentId =
                    JSON.parse(sessionStorage.getItem('pageData')).searchVal
                        .departmentId || undefined;
                this.$refs.department.$data.adminId =
                    JSON.parse(sessionStorage.getItem('pageData')).searchVal
                        .adminId || '';
                if (this.searchVal.departmentId) {
                    if (this.btnP.userSearch) {
                        this.$refs.department.getUserList(
                            JSON.parse(sessionStorage.getItem('pageData'))
                                .searchVal.departmentId
                        );
                    }
                }

                this.active = JSON.parse(
                    sessionStorage.getItem('pageData')
                ).active;
                sessionStorage.removeItem('pageData');
                this.isPage = true;
            } else {
                this.isPage = true;
            }
            if (this.$route.query.adminId) {
                this.searchVal.departmentId = Number(
                    this.$route.query.departmentId
                );
                this.$refs.department.getUserList(
                    Number(this.$route.query.departmentId)
                );
                this.$refs.department.$data.adminId = Number(
                    this.$route.query.adminId
                );
                this.searchVal.adminId = Number(this.$route.query.adminId);
            }
            if (this.$route.query.departmentId) {
                this.searchVal.departmentId = Number(
                    this.$route.query.departmentId
                );
                this.$refs.department.getUserList(
                    Number(this.$route.query.departmentId)
                );
                this.$refs.department.$data.departmentId = Number(
                    this.$route.query.departmentId
                );
                // this.searchVal.adminId = Number(this.$route.query.adminId);
            }
            if (this.$route.query.month) {
                this.searchVal.accountMonth = this.$route.query.month;
            }
            this.getData();
        },
        getAllUser() {
            let data = {
                param: {
                    roleTypeList: [3, 4, 8, 9, 10],
                },
            };
            roleList(data).then((res) => {
                this.allUserList = res.data;
            });
        },
        // 获取部门
        getDepartmentList() {
            let data = {
                param: {},
            };
            departmentList(data).then((res) => {
                // console.log(res);
                if (res.code == 200) {
                    this.departmentList = res.data;
                    this.$refs.department.getData(this.btnP, res.data);
                }
            });
        },
        changeProductId(val) {
            this.pagesize = 20;
            this.currentPage = 1;
            this.searchVal.productCategory = val;
            this.getData();
        },
        getTableData() {
            this.currentPage = 1;
            this.getData();
        },
        searchData(data) {
            this.pagesize = 20;
            this.pageNum = 1;
            this.searchVal.departmentId = data.departmentId;
            this.searchVal.adminId = data.adminId;
            this.getData();
        },
        getData() {
            this.$refs.table.getData(
                this.active,
                this.searchVal,
                this.currentPage,
                this.pagesize,
                this.btnP
            );
        },
        totalNum(row) {
            this.total = row;
        },
        changeActive(active) {
            this.active = active;
            this.pagesize = 20;
            this.pageNum = 1;
            this.getData();
        },
        // 分页
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getData();
        },
        handleSizeChange(val) {
            this.pagesize = val;
            this.getData();
        },
        handleClose() {
            this.dialogGenerate = false;
            this.dialogSend = false;
            this.form = {
                radio: 1,
            };
            this.getData();
        },
        handleDrawer() {
            this.drawerAli = false;
            this.drawerHistory = false;
            this.drawerDetails = false;
            this.getData();
        },
        //邮件发送业绩明细
        onEmailDetail(){
            let data = {
                param: {
                }
            };
            if (this.searchVal.accountMonth) {
                data.param.accountMonth = this.searchVal.accountMonth;
            }
            performanceDetail(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('发送成功');
                }
            });
        },

        onEmailFiance(){
            let data = {
                param: {
                }
            };
            if (this.searchVal.accountMonth) {
                data.param.accountMonth = this.searchVal.accountMonth;
            }
            performanceEmailFiance(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('发送成功');
                }
            });
        },
        onClose() {
            this.$router.go(-1);
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.deal_with_box {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .search-box {
        width: 100%;
        background: #fff;
        border-radius: 4px;
        margin-bottom: 16px;
        span {
            font-size: 12px;
            font-weight: 600;
            color: #333333;
            margin: 0 16px;
        }
        .search-one {
            padding: 10px 0 12px 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .left {
                .btn1,
                .btn2 {
                    display: inline-block;
                    width: 96px;
                    height: 32px;
                    background: #f0f0f0;
                    box-shadow: 0px 2px 4px 0px rgba(252, 239, 198, 0.6);
                    border-radius: 2px;
                    text-align: center;
                    line-height: 32px;
                    font-weight: normal;
                    cursor: pointer;
                    img {
                        width: 16px;
                        height: 16px;
                        vertical-align: -3px;
                        margin-right: 3px;
                    }
                }
                .btn2.active {
                    background: #f7ece5;
                    margin: 0 8px 0 0;
                }
                .btn1.active {
                    background: #fcefc6;
                }
                button {
                    background: #2370eb;
                    border-radius: 2px;

                    margin-right: 16px;
                }
            }
        }
        .search-two {
            padding-bottom: 10px;
        }
    }
    .table-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background: #fff;
        padding: 16px;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        .title_left_btn {
            span {
                display: inline-block;
                padding: 4px 13px;
                border-radius: 20px;
                font-size: 13px;
                color: #666666;
                border: 1px solid #f0f0f0;
                cursor: pointer;
            }
            span + span {
                margin-left: 12px;
            }
            .active {
                background: #e9f2ff;
                color: #2370eb;
            }
        }
        .add {
            border-color: #d9d9d9;
            color: #fff;
        }
        p {
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
            float: right;
        }
        button {
            float: right;
            font-size: 12px;
            padding: 0 7px 0 12px;
            height: 24px;
            background: #ffffff;
            border-radius: 2px;
            border: 1px solid #2370eb;
            cursor: pointer;
            color: #2370eb;
            margin-left: 16px;
            text-align: center;
        }
    }
    .page-box {
        background: #fff;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .Times {
        display: inline-block;
        width: 20px;
        line-height: 16px;
        font-size: 12px;
        font-weight: 500;
        color: #ffffff;
        background: #ff760f;
        border-radius: 63px 63px 63px 1px;
        text-align: center;
    }
    .Timess {
        background: #ff9385;
        margin-left: 3px;
    }
}
.dialog_info {
    width: 85%;
    margin: 25px auto 0;
    padding-bottom: 110px;
    font-size: 14px;
    color: #333333;
    p {
        margin-bottom: 16px;
        font-weight: 600;
    }
    span {
        display: inline-block;
        width: 215px;
        height: 48px;
        background: #f9fafc;
        border-radius: 8px;
        text-align: center;
        line-height: 48px;
        cursor: pointer;
        i {
            color: #2370eb;
        }
    }
    .icon-zhuyi {
        display: block;
        margin: 0 auto 45px;
        font-size: 40px;
        color: #feb516;
    }
}
/deep/.el-dialog__footer {
    padding: 0;
    text-align: right;
    height: 50px;
    line-height: 50px;
    border-top: 1px solid #eeeeee;
    padding-right: 18px;
}
.item {
    margin-left: 12px;
    margin-top: -2px;
    margin-right: 12px;
}
/deep/ .el-badge__content.is-fixed {
    top: 5px;
    right: 12px;
}
</style>
