<template>
    <div class="contact_info_box">
        <div style="padding-bottom:16px;height: 390px;">
            <p class="info">
                <span>业绩编号</span>
                {{ performance.performanceNo ? performance.performanceNo : '- -' }}
            </p>
            <p class="info">
                <span>客户名称</span>
                {{ performance.companyName ? performance.companyName : '- -' }}
            </p>
            <p class="info">
                <span>成员</span>
                {{ performance.adminName ? performance.adminName : '- -' }}
            </p>
            <p class="info">
                <span>结算价</span>
                {{ performance.settleAmount ? performance.settleAmount : '- -' }}
            </p>
            <p class="info">
                <span>业绩</span>
                {{ performance.performanceAmount ? performance.performanceAmount : '- -' }}
            </p>



            <p class="info">
                <span>新结算价</span>
                <el-input
                    v-model="settleAmount"
                    style="width: 210px"
                    placeholder="结算价"
                ></el-input>
            </p>
            <p class="info">
                <span>新业绩</span>
                <el-input
                    v-model="performanceAmount"
                    style="width: 210px"
                    placeholder="结算价"
                ></el-input>
            </p>

            
        </div>
        <div class="footer_btn">
            <el-button size="mini" round @click="onClose">取 消</el-button>
            <el-button type="primary" size="mini" @click="onSubmit" round>保 存</el-button>
        </div>
    </div>
</template>

<script>
import { performanceUpdateSet } from '@/api/performance/performance.js';
export default {
    data() {
        return {
            performance: {},
            settleAmount:'',
            performanceAmount: ''
        };
    },
    methods: {
        getData(row) {
            this.performance = row;
            // console.log(row);
        },
        onClose() {
            this.$emit('close');
            this.settleAmount = '';
            this.performanceAmount = '';
        },
        onSubmit() {
            if (!this.settleAmount) {
                return this.$message.error('新结算价不能为空');
            }
            let data = {
                param: {
                    id: this.performance.id,
                    settleAmount: this.settleAmount,
                    performanceAmount: this.performanceAmount
                },
            };
            performanceUpdateSet(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.$emit('close');
                    this.settleAmount = '';
                    this.performanceAmount = '';
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.contact_info_box {
    width: 100%;
    box-sizing: border-box;
    background-color: #fff;
    .title {
        line-height: 1.2;
        margin-top: 12px;
        margin-bottom: 12px;
        font-size: 16px;
        color: #333;
        padding-left: 10px;
        > i {
            color: #2370eb;
            font-size: 18px;
        }
        button {
            background: rgb(255, 255, 255);
            color: rgb(35, 112, 235);
            border-color: rgb(35, 112, 235);
            padding: 5px 10px;
            float: right;
            margin-right: 20px;
        }
    }
    .el-form {
        margin-left: 25px;
        /deep/ .el-form-item__label {
            font-weight: 600 !important;
        }
    }
    .info {
        font-size: 14px;
        color: #333;
        padding-left: 107px;
        box-sizing: border-box;
        margin-top: 15px;
        span {
            display: inline-block;
            width: 90px;
        }
    }
}
.footer_btn {
    width: 100%;
    height: 50px;
    line-height: 50px;
    text-align: right;
    border-top: 1px solid #e5e5e5;
    background-color: #fff;
    .el-button--primary {
        background-color: #2370eb;
        border-color: #2370eb;
    }
    button:last-child {
        margin-right: 30px;
    }
}
</style>
