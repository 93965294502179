import http from '@/utils/http.js'

// 业绩列表
export function performanceList(data) {
     return http({
          url: "/jasoboss/performance/list",
          method: "post",
          data
     })
}
// 业绩列表单个
export function performanceGet(data) {
     return http({
          url: "/jasoboss/performance/get",
          method: "post",
          data
     })
}
// 业绩列表修改
export function performanceUpdate(data) {
     return http({
          url: "/jasoboss/performance/update",
          method: "post",
          data
     })
}
// 查询标的的所有人员
export function contractTargetStaffGet(data) {
     return http({
          url: "/jasoboss/contract/get/target/staff",
          method: "post",
          data
     })
}
// 业绩历史
export function performanceHistoryList(data) {
     return http({
          url: "/jasoboss/performance/history/list",
          method: "post",
          data
     })
}
export function performanceBriefGet(data) {
     return http({
          url: "/jasoboss/performance/brief/get",
          method: "post",
          data
     })
}
export function performanceTargetBriefGet(data) {
     return http({
          url: "/jasoboss/performance/brief/get/target",
          method: "post",
          data
     })
}
export function performanceBriefList(data) {
     return http({
          url: "/jasoboss/performance/brief/list",
          method: "post",
          data
     })
}
export function performanceBriefProductList(data) {
     return http({
          url: "/jasoboss/performance/brief/product/list",
          method: "post",
          data
     })
}
//业绩明细导出
export function performanceDetail(data) {
     return http({
          url: "/jasoboss/performance/export/detail",
          method: "post",
          data
     })
}

//业绩统计导出
export function performanceEmailFiance(data) {
     return http({
          url: "/jasoboss/performance/export/statistics",
          method: "post",
          data
     })
}



export function performanceContractBrief(data) {
     return http({
          url: "/jasoboss/performance/brief/contract/statistics",
          method: "post",
          data
     })
}


export function performanceProductBrief(data) {
     return http({
          url: "/jasoboss/performance/brief/product/statistics",
          method: "post",
          data
     })
}



export function performanceQuotaBrief(data) {
     return http({
          url: "/jasoboss/performance/brief/quota/statistics",
          method: "post",
          data
     })
}



// 业绩修改
export function performanceUpdateSet(data) {
     return http({
          url: "/jasoboss/performance/set/update",
          method: "post",
          data
     })
}
